import React from "react";
import clsx from "clsx";
import { classBox } from "@/assets/tailwind/classBox";
import IconWeather from "@/components/page/home/icon/IconWeather";
import { helpers } from "@/utils/helpers";
import { IWeather } from "@/models/IWeather";

interface IWeatherInfo {
  weatherData: IWeather;
}
const WeatherInfo: React.FC<IWeatherInfo> = ({ weatherData }) => {
  const returnTemp = (temp_min: number, temp_max: number) => {
    const result = "N/A";
    if (!temp_min || !temp_max) {
      return result;
    }
    if (temp_min === temp_max) {
      return <span className="mr-2">{helpers.convertToCelsius(temp_min)}</span>;
    }
    return (
      <div>
        <span className="mr-2">{helpers.convertToCelsius(temp_min)}</span>
        <span className="mr-2">-</span>
        <span className="mr-2">{helpers.convertToCelsius(temp_max)}</span>
      </div>
    );
  };
  return (
    <>
      <div className={clsx(classBox["lg:fcc"], "mt-5 md:mt-10")}>
        <div className="flex grow">
          <div className="text-3xl md:text-9xl mr-5 md:mr-10">
            {helpers.convertToCelsius(weatherData.main?.temp)}
            <div className="md:hidden block text-sm whitespace-nowrap">
              {weatherData.city}, {weatherData.country}
            </div>
          </div>
          <div className="mr-20 -mt-2 md:mt-4">
            <div className="hidden md:block">
              {weatherData.city}, {weatherData.country}
            </div>
            <div className="flex item-center">
              <div className="md:text-3xl md:my-4">
                {weatherData.weather.map((item, index) => {
                  return (
                    <div className={clsx(classBox.fsc)} key={item.id}>
                      <div className="mr-2 min-w-[60px]">
                        <IconWeather weatherIcon={item.icon} />
                      </div>
                      <div>
                        <span className="mr-2">{item.main}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="rounded-lg overflow-hidden mt-5 md:mt-0">
          <table className="w-full">
            <tbody>
              <tr>
                <td className={clsx(classBox.tdSearch)}>Description</td>
                <td className={clsx(classBox.tdSearchResult)}>
                  {weatherData.weather.map((item, index) => {
                    return (
                      <div key={"item_" + index}>
                        <span className="mr-2">{item.description}</span>
                      </div>
                    );
                  })}
                </td>
              </tr>
              <tr>
                <td className={clsx(classBox.tdSearch)}>Temperature</td>
                <td className={clsx(classBox.tdSearchResult)}>
                  {returnTemp(
                    weatherData.main.temp_min,
                    weatherData.main.temp_max
                  )}
                </td>
              </tr>
              <tr>
                <td className={clsx(classBox.tdSearch)}>Humidity</td>
                <td className={clsx(classBox.tdSearchResult)}>
                  {weatherData.main.humidity}%
                </td>
              </tr>
              <tr>
                <td className={clsx(classBox.tdSearch)}>
                  Time
                </td>
                <td className={clsx(classBox.tdSearchResult)}>
                  {helpers.formatDateTime(new Date())}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default WeatherInfo;
