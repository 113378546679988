import { classBox } from "@/assets/tailwind/classBox";
import { imgAssets } from "@/constants/img";
import clsx from "clsx";
import React from "react";

interface ISearchBox {
  searchWeather: (e: React.FormEvent<HTMLFormElement>) => void;
  searchMsg: string;
  formData: {
    city: string;
    country: string;
  };
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  clearSearch: () => void;
}
const SearchBox: React.FC<ISearchBox> = ({
  searchMsg,
  formData,
  searchWeather,
  onSearchChange,
  clearSearch,
}) => {
  return (
    <>
      <form className="md:flex items-end gap-2" onSubmit={searchWeather}>
        <div className="flex items-start md:w-[100%]">
          <div className="w-1/2">
            <span className="text-base mr-2 text-white">City: </span>
            <div className="relative border-[3px] border-white rounded-lg border-opacity-10 overflow-hidden">
              <imgAssets.ApartmentIcon className="text-primary-400 absolute auto-y left-2" />
              <input
                className={clsx(classBox.inputText, "!pl-10")}
                name="city"
                value={formData.city}
                onChange={(e) => onSearchChange(e)}
                placeholder="City Name"
              />
            </div>
          </div>
          <div className="w-1/2">
            <span className="text-base mr-2 text-white">Country: </span>
            <div className="border-[3px] border-white rounded-lg border-opacity-10 overflow-hidden relative">
              <imgAssets.LanguageIcon className="!text-primary-400 absolute auto-y left-2" />
              <input
                value={formData.country}
                name="country"
                onChange={(e) => onSearchChange(e)}
                className={clsx(classBox.inputText, "!pl-10")}
                placeholder="Country Name"
              />
            </div>
          </div>
        </div>
        <div className={clsx(classBox.fec, "mt-2")}>
          <div className="border-[3px] border-white rounded-lg border-opacity-10 overflow-hidden">
            <button className={clsx(classBox.buttonPrimary)}>
              <imgAssets.SearchIcon className="mr-2" />
              Search
            </button>
          </div>
          <div className="border-[3px] border-white rounded-lg border-opacity-10 overflow-hidden ml-2">
            <button
              className={clsx(
                classBox.buttonBorderPrimary,
                "w-full !bg-transparent text-white border-none"
              )}
              type="button"
              onClick={() => clearSearch()}
            >
              Clear
            </button>
          </div>
        </div>
      </form>
      {searchMsg && <div className="text-red-500 mt-2">{searchMsg}</div>}
    </>
  );
};

export default SearchBox;