import { store } from "@/store";
import { createContext, useCallback, useEffect, useMemo, useRef, useState,  } from "react"; 
import { useSelector } from "react-redux";
import { debounce } from "lodash";
export const useHook = () => {  
  return {
    debounce: debounce,
    dispatch: store.dispatch, 
    getState: store.getState,
    useSelector: useSelector,
    useEffect: useEffect,
    useState: useState,
    useMemo: useMemo,
    useCallback: useCallback,    
    useRef: useRef,
  }
}
export type TContextHook = ReturnType<typeof useHook>;
export const HookContext = createContext({});