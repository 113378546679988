import { IDataResponse } from "@/models/IDataResponse";
import { IWeatherService, WeatherService } from "../services/weather.service";

export interface IWeatherRepo {
	get(params: string): Promise<IDataResponse<any>>;
}

export class WeatherRepo implements IWeatherRepo {
	private weatherService: IWeatherService;
	constructor() {
		this.weatherService = new WeatherService();
	}
	async get(params: string): Promise<IDataResponse<any>> {
		const response = await this.weatherService.get(params);
		return response;
	}
}