import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
type IMenuControl = {
  [key: string]: any;
}
interface IState {
  isLoading: boolean;
  msg: any,
  controllSidebar: IMenuControl,
}
const initialState: IState = {
  isLoading: false,
  msg: {},
  controllSidebar: {
    isHideText: false,
    isShow: false,
  }
}
export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setMsg: (state, action) => {
      const { text, status } = action.payload;
      state.msg = { text, status };
    },
    clearMsg: (state, action) => {
      state.msg = {};
    },
    setControllSidebar: (state, action) => {
      const data = action.payload;
      state.controllSidebar = { ...state.controllSidebar, ...data };
    }
  },
});

export default globalSlice;
export const globalReducer = globalSlice.reducer;
export const { setLoading, setMsg, setControllSidebar } = globalSlice.actions;