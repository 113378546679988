
import axios, {
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
	AxiosError,
} from "axios";
import { getErrorMsg } from "@/utils/getErrorMsg";
import { IDataResponse } from "@/models/IDataResponse";
class AxiosService {
	// call axiosInstance
	private readonly axiosInstance: AxiosInstance;
	constructor(baseURL?: string) {
		this.axiosInstance = axios.create({
			baseURL: process.env.REACT_APP_API_URL || "https://api.openweathermap.org/data/2.5/weather",
			// withCredentials: true, // IMPORTANT: enable sending cookies and authorization headers CORS
		});
	}

	async get<T = any, R = AxiosResponse<T>>(
		url: string,
		config?: AxiosRequestConfig
	): Promise<IDataResponse<T>> {
		try {
			const response: any = await this.axiosInstance.get(url, config);
			return {
				data: response.data,
				success: response.status === 200,
				message: response.message,
			};
		} catch (error) {
			return this.handleError(error);
		}
	}


	private handleError<T>(error: any): IDataResponse<T> {
		if (error.response) {
			const message = getErrorMsg(error.response.data);
			return { data: null, success: false, message };
		} else if (error.request) {
			return {
				data: null,
				success: false,
				message: "No response received from the server",
			};
		} else {
			return {
				data: null,
				success: false,
				message: "An error occurred while processing the request",
			};
		}
	}
}

const axiosService = new AxiosService();
export default axiosService;