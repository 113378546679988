import {
  useSelector,
  useDispatch,
} from 'react-redux';
import { AppDispatch, RootState } from '../store';
import React, { useEffect } from 'react';

const Msg = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { msg } = useSelector((state: RootState) => state.global);

  useEffect(() => {
    const setClearMsg = () => {
      const timer = setTimeout(() => {
        if (msg.text) {
          dispatch({ type: 'global/setMsg', payload: { text: '', status: 1 } });
        }
      }, 4000);
      return () => clearTimeout(timer);
    };
    setClearMsg();
  }, [msg, dispatch]);

  const msgClass = `msg absolute transition transition-all z-50 w-[90%] max-w-[350px] p-2 text-center auto-x top-2 rounded bg-white border ${msg.status === -1 ? 'text-red border-red' : msg.status === 0 ? 'text-yellow border-yellow' : 'text-green border-green'}`;
  const textClass = 'text-sm';

  return (
    msg.text ? (
      <div className={msgClass}>
        <div className={textClass}>{msg.text}</div>
      </div>
    ) : null
  )
}

export default Msg;
