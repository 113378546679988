import {
  configureStore, ThunkAction, Action,
  combineReducers,
} from "@reduxjs/toolkit";
import globalSlice from "@/store/global";
import weatherSlice from "./weather";

const reducer = combineReducers({
  [weatherSlice.name]: weatherSlice.reducer,
  [globalSlice.name]: globalSlice.reducer,
});
export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['persist/PERSIST'],
    },
  }).concat(),

});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
export type AppDispatch = typeof store.dispatch;