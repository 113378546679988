import Logo from "@/assets/images/logo.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";
import DehazeIcon from "@mui/icons-material/Dehaze";
import SearchIcon from "@mui/icons-material/Search";
import LogoutIcon from "@mui/icons-material/Logout";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewSidebarIcon from "@mui/icons-material/ViewSidebar";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import BadgeIcon from "@mui/icons-material/Badge";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import EmailIcon from "@mui/icons-material/Email";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import ListIcon from '@mui/icons-material/List';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ApartmentIcon from '@mui/icons-material/Apartment';
import LanguageIcon from '@mui/icons-material/Language';
import TrashIcon from '@mui/icons-material/Delete';

export const imgAssets = {
  Logo,
  TrashIcon,
  LanguageIcon,
  ApartmentIcon,
  DehazeIcon,
  SearchIcon,
  ExpandMoreIcon,
  PersonIcon,
  LogoutIcon,
  ViewListIcon,
  ViewSidebarIcon,
  PeopleAltIcon,
  BadgeIcon,
  VpnKeyIcon,
  EmailIcon,
  AccountBoxIcon,
  ContactPageIcon,
  AccountCircleIcon,
  UploadFileIcon,
  FeaturedPlayListIcon,
  ListIcon,
  ListAltIcon,
  CloudUploadIcon,
  AutorenewIcon,
  CheckCircleIcon,
  ChevronLeftIcon,
  ChevronRightIcon
};