import Msg from "@/components/Msg";
import Header from "@/components/header/Header";
import { HookContext, useHook } from "@/context/hook";
import { store } from "@/store";
import { Provider } from "react-redux";
import { Main } from "@/components/wrapperLayout/Main";
import React from "react";
// import 'virtual:windi.css'

export const HomeLayout: React.FC = (props) => {
  const hookContext = useHook() as any;
  return (
    <Provider store={store}>
      <HookContext.Provider value={hookContext}>
        <Msg />
        <div className="w-full flex-none transition-colors duration-500">
          <Header />
          <Main />
        </div>
      </HookContext.Provider>
    </Provider>
  );
};
