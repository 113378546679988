export const helpers = {
	formatDateTime(date: Date) {
		const options: Intl.DateTimeFormatOptions = {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
			hour12: true,
		};
		const formatter = new Intl.DateTimeFormat('en-US', options);
		const formattedDateTime = formatter.format(date);
		return formattedDateTime;
	},
	convertToCelsius(kelvin: number) {
		if (!kelvin) return 'N/A';
		return Math.round(kelvin - 273.15) + '°C';
	},

	converTimeToText(time: number) {
		const savedDate = new Date(time);
		const hours = savedDate.getHours();
		const minutes = savedDate.getMinutes();
		const amOrPm = hours >= 12 ? 'PM' : 'AM';
		const formattedTime = `${(hours % 12) || 12}:${minutes < 10 ? '0' : ''}${minutes} ${amOrPm}`;
		return formattedTime;
	}

}