import Icon01d from "@/assets/images/icons/icon01d.png";
import Icon01n from "@/assets/images/icons/icon01n.png";
import Icon02d from "@/assets/images/icons/icon02d.png";
import Icon02n from "@/assets/images/icons/icon02n.png";
import Icon03d from "@/assets/images/icons/icon03d.png";
import Icon03n from "@/assets/images/icons/icon03n.png";
import Icon04d from "@/assets/images/icons/icon04d.png";
import Icon04n from "@/assets/images/icons/icon04n.png";
import Icon09d from "@/assets/images/icons/icon09d.png";
import Icon09n from "@/assets/images/icons/icon09n.png";
import Icon11d from "@/assets/images/icons/icon11d.png";
import Icon11n from "@/assets/images/icons/icon11n.png";
import Icon13d from "@/assets/images/icons/icon13d.png";
import Icon13n from "@/assets/images/icons/icon13n.png";
import Icon50d from "@/assets/images/icons/icon50d.png";
import Icon50n from "@/assets/images/icons/icon50n.png";
import Icon10d from "@/assets/images/icons/icon10d.png";
import Icon10n from "@/assets/images/icons/icon10n.png";

export const iconWeather = {
	Icon01d: {
		code: "01d",
		name: "clear sky",
		icon: Icon01d
	},
	Icon01n: {
		code: "01n",
		name: "clear sky",
		icon: Icon01n
	},
	Icon02d: {
		code: "02d",
		name: "few clouds",
		icon: Icon02d
	},
	Icon02n: {
		code: "02n",
		name: "few clouds",
		icon: Icon02n
	},
	Icon03d: {
		code: "03d",
		name: "scattered clouds",
		icon: Icon03d
	},
	Icon03n: {
		code: "03n",
		name: "scattered clouds",
		icon: Icon03n
	},
	Icon04d: {
		code: "04d",
		name: "broken clouds",
		icon: Icon04d
	},
	Icon04n: {
		code: "04n",
		name: "broken clouds",
		icon: Icon04n
	},
	Icon09d: {
		code: "09d",
		name: "shower rain",
		icon: Icon09d
	},
	Icon09n: {
		code: "09n",
		name: "shower rain",
		icon: Icon09n
	},
	Icon10d: {
		code: "10d",
		name: "clear sky",
		icon: Icon10d
	},
	Icon10n: {
		code: "10n",
		name: "clear sky",
		icon: Icon10n
	},
	Icon11d: {
		code: "11d",
		name: "thunderstorm",
		icon: Icon11d
	},
	Icon11n: {
		code: "11n",
		name: "thunderstorm",
		icon: Icon11n
	},
	Icon13d: {
		code: "13d",
		name: "snow",
		icon: Icon13d
	},
	Icon13n: {
		code: "13n",
		name: "snow",
		icon: Icon13n
	},
	Icon50d: {
		code: "50d",
		name: "mist",
		icon: Icon50d
	},
	Icon50n: {
		code: "50n",
		name: "mist",
		icon: Icon50n
	}
}