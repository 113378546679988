export const classBoxGeneral = {
	mbBase: "md:mb-8 mb-6",
	mtBase: "md:mt-8 mt-6",
	fcc: "flex flex-row justify-center items-center",
	fbc: "flex flex-row justify-between items-center",
	fac: "flex flex-row justify-around items-center",
	fec: "flex flex-row justify-end items-center",
	fss: "flex flex-row justify-start items-start",
	fsc: "flex flex-row justify-start items-center",
	fee: "flex flex-row justify-end items-end",
	mdflex: "md:flex",
	"md:fss": "md:flex flex-row justify-start items-start",
	"md:fsc": "md:flex flex-row justify-start items-center",
	"md:fcc": "md:flex flex-row justify-center items-center",
	"md:fbc": "md:flex flex-row justify-between items-center",
	"md:fac": "md:flex flex-row justify-around items-center",
	"md:fec": "md:flex flex-row justify-end items-center",
	"md:fee": "md:flex flex-row justify-end items-end",
	"lg:fss": "lg:flex flex-row justify-start items-start",
	"lg:fsc": "lg:flex flex-row justify-start items-center",
	"lg:fcc": "lg:flex flex-row justify-center items-center",
	"lg:fbc": "mlgd:flex flex-row justify-between items-center",
	"lg:fac": "lg:flex flex-row justify-around items-center",
	"lg:fec": "lg:flex flex-row justify-end items-center",
	"lg:fee": "lg:flex flex-row justify-end items-end",
	inputText: "pl-2 h-10 w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded transition ease-in-out duration-150 focus:shadow-outline",
	inputCheckbox: "h-4 w-4 text-primary focus:shadow-outline focus:rounded focus:ring-primary-500 border-gray-300 rounded",
	buttonPrimary: "flex justify-center items-center h-10 px-4 bg-primary md:hover:bg-primary-400 text-white duration-300 transition-color text-sm font-medium rounded disabled:bg-gray-200 disabled:cursor-not-allowed",
	buttonBorder: "flex justify-center items-center h-10 px-4 bg-white border border-gray-300 md:hover:bg-gray-50 text-gray-700 duration-300 transition-color text-sm font-medium rounded disabled:bg-gray-200 disabled:cursor-not-allowed",
	buttonBorderPrimary: "flex justify-center items-center h-10 px-4 bg-white border border-primary bg-primary-50 md:hover:bg-primary-100 text-primary duration-300 transition-color text-sm font-medium rounded disabled:bg-gray-200 disabled:cursor-not-allowed",
	buttonLarge: "h-12 md:h-14 min-w-[100px] text-[1.2rem] md:text-[1.4rem] font-bold",
	buttonSmall: "h-8 min-w-[100px] text-[0.8rem] font-bold",
	select: "pl-2 h-10 w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded transition ease-in-out duration-150 focus:shadow-outline",
	required: "before:content-['*'] before:text-red-500 before:font-bold before:mr-1",
	buttonBig: "h-12 md:h-14 min-w-[100px] text-[1.2rem] md:text-[1.4rem] font-bold",
	triangle: "before:content-[\"\"] before:border-solid before:border-l-black before:border-l-8 before:border-y-transparent before:border-y-8 before:border-r-0 before:absolute",
	typography: "!text-[1rem] max-w-full prose-table:border prose-table:w-[100%] prose-td:border prose-td:p-2",
	link: "text-primary lg:hover:text-primary-400 transition-color duration-300",
	overflowEllipsis: "overflow-hidden whitespace-nowrap overflow-ellipsis",
	imageFit: "aspect-square cursor-pointer object-cover object-center !relative",
	hoverText: "hover:opacity-60 transition-opacity duration-300",
	fadeInUp: "fadeInUp ease-in-out",
	fadeInLeft: "fadeInLeft ease-in-out",
	fadeInRight: "fadeInRight ease-in-out",
}
export const classBoxHeader = {
	tdSearch: "py-2 px-4 md:!px-10 2xl:!px-20 border-b border-white border-opacity-10 bg-white bg-opacity-20",
	tdSearchResult: "pl-3 py-2 px-4 md:!px-10 2xl:!px-20 border-b border-white border-opacity-10 bg-white bg-opacity-20"
}
export const classBoxSidebar = {
	sidebar: "py-3 px-4 lg:border-r border-gray-200"
}
export const tableAdmin = {
	adminTable: "min-w-[1000px] w-full table-auto text-left text-sm border ",
	adminThead: "border-b font-medium bg-gray-100 dark:border-gray-50",
	adminTheadTd: "border px-3 py-3 text-left text-xs whitespace-nowrap	 font-medium text-gray-500 uppercase tracking-wider",
	adminTbodytd: "border px-3 py-2 text-sm text-gray-500",

	iconAction: "text-large lg:hover:text-opacity-80 transition flex bg-opacity-20 w-6 h-6 cursor-pointer rounded-full flex justify-center items-center",
	iconActionEdit: "bg-primary text-primary",
	iconActionDelete: "bg-red text-red",
	iconActionView: "bg-green text-green",
}
export const ecommerce = {
	smallPrice: "text-sm font-medium text-gray-500 line-through flex items-center space-x-1 mr-3",
	biggerPrice: "text-2xl font-bold text-primary",
	productDetailTitle: "text-xl font-medium text-gray-900 bg-gray-50 py-3 px-4 mb-4 rounded",
}
export const userInterfaceClassBox = {
	userLayout: "w-full mx-auto px-4 md:px-8 xl:px-0 max-w-[1200px]",
	userLayoutFullMobile: "w-full mx-auto px-0 md:px-8 xl:px-0 max-w-[1280px]",
	userBeforeNav: "before:absolute before:w-0 before:before:bg-brand before:h-[3px] before:transition-all before:duration-300 before:-bottom-[14px]",
}

export const classBox = {
	...classBoxHeader,
	...classBoxGeneral,
	...classBoxSidebar,
	...tableAdmin,
	...ecommerce,
	...userInterfaceClassBox
}