import React from "react";
import clsx from "clsx";
import { classBox } from "@/assets/tailwind/classBox";
import { useHome } from "@/components/page/home/useHome";
import { helpers } from "@/utils/helpers";
import { imgAssets } from "@/constants/img";
import SearchBox from "@/components/page/home/searchbox/SearchBox";
import WeatherInfo from "@/components/page/home/info/WeatherInfo";

const Home: React.FC = () => {
  const {
    searchMsg,
    formData,
    weatherData,
    searchHistory,
    searchResult,
    clearSearch,
    onSearchChange,
    searchWeather,
    reSearchWeather,
    removeHistory,
  } = useHome();
  return (
    <>
      {weatherData && (
        <div className={"text-white"}>
          <div>
            <SearchBox
              searchMsg={searchMsg}
              formData={formData}
              searchWeather={searchWeather}
              onSearchChange={onSearchChange}
              clearSearch={clearSearch}
            />
          </div>
          <WeatherInfo weatherData={weatherData} />
        </div>
      )}

      {searchHistory && searchHistory.length > 0 && (
        <div className="mt-10">
          {!searchResult && searchResult !== null && (
            <div className="text-white">
              <div className="text-base text-center bg-white text-red p-4 rounded mb-5">
                No search history found. Please search weather by city and
                country.
              </div>
            </div>
          )}
          <h3 className="text-white">Search History</h3>
          <div className="block w-full overflow-x-auto bg-white rounded-lg overflow-hidden mt-2">
            <div className="items-center bg-transparent w-full border-collapse">
              {searchHistory.map((item, index) => {
                return (
                  <div
                    key={"item_" + index}
                    className={clsx(
                      classBox.fbc,
                      "border-b border-gray-300 px-6 py-4"
                    )}
                  >
                    <div className="">
                      {item.city}, {item.country}
                    </div>
                    <div className={clsx(classBox.fec)}>
                      <div>
                        {item.searchedTime && (
                          <span className="mr-2 text-sm text-gray-500 md:text-base">
                            {helpers.converTimeToText(item.searchedTime)}
                          </span>
                        )}
                      </div>
                      <div className="ml-2">
                        <button onClick={() => reSearchWeather(item)}>
                          <imgAssets.SearchIcon className="!w-5" />
                        </button>
                      </div>
                      <div className="ml-2">
                        <button onClick={() => removeHistory(item)}>
                          <imgAssets.TrashIcon className="!w-5 !text-red" />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
