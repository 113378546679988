class Refactor {
	private static instance: Refactor;
	private constructor() { }
	public static getInstance(): Refactor {
		if (!Refactor.instance) {
			Refactor.instance = new Refactor();
		}
		return Refactor.instance;
	}
	public refactorWeather(data: any): any {
		const {
			weather, main, timezone, name, sys
		} = data;
		return {
			id: data.id,
			weather,
			main,
			timezone,
			city: name,
			country: sys?.country
		}
	}
}
export default Refactor.getInstance();