import { Outlet } from "react-router-dom";
import clsx from "clsx";
import { classBox } from "@/assets/tailwind/classBox";
import Bg from "@/assets/images/bg.webp";

export const Main: React.FC = (props) => {
  return (
    <div className="bg-gradient-to-b from-primary-500 min-h-screen to-primary-100 pt-20 pb-14" style={{
      backgroundImage: `url(${Bg})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    }}>
      <div className={clsx(classBox.userLayout)}>
        <Outlet />
      </div>
    </div>
  );
};
