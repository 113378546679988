import { IWeather } from "@/models/IWeather";
import { createSlice } from "@reduxjs/toolkit";

export interface IWeatherExpand extends IWeather {
	searchedCity: string;
	searchedCountry: string;
	searchedTime: number;
}
interface IState {
	weatherData: IWeatherExpand | null;
	searchHistory: IWeatherExpand[];
}
const initialState: IState = {
	weatherData: null,
	searchHistory: [],
}
export const weattherSlice = createSlice({
	name: "weather",
	initialState,
	reducers: {
		setWeatherData: (state, action) => {
			state.weatherData = action.payload.data;
			const isIdExists = state.searchHistory.some((item) => item.id === action.payload.data?.id);
			if (!isIdExists) {
				if (!action.payload?.isInnit) {
					state.searchHistory.push(action.payload.data);
				}
			} else {
				// remove old item
				const index = state.searchHistory.findIndex((item) => item.id === action.payload.data?.id);
				state.searchHistory.splice(index, 1);
				// add new item
				state.searchHistory.push(action.payload.data);
			}
		},
		setSearchHistory: (state, action) => {
			state.searchHistory = action.payload;
		}
	},
});

export default weattherSlice;
export const globalReducer = weattherSlice.reducer;
export const { setWeatherData, setSearchHistory } = weattherSlice.actions;