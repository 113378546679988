import axiosService from "@/datasource/services/axiosServices";
import { IWeather } from "@/models/IWeather";
import { IDataResponse } from "@/models/IDataResponse";
import refactor from "@/datasource/refactor";
export interface IWeatherService {
  get(params: string): Promise<IDataResponse<IWeather>>;
}
export class WeatherService implements IWeatherService {
  private prefix: string;
  constructor() {
    this.prefix = '/';
  }
  async get(params: string): Promise<IDataResponse<IWeather>> {
    let response = await axiosService.get(`${this.prefix}`, {
      params: {
        q: params,
        appid: process.env.APIKEY || "683c9c12a2c71d624cbb2df9ba4c6ac2",
      }
    });
    if (response.data) {
      response.data = refactor.refactorWeather(response.data);
    }
    return response;
  }
}