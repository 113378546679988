import { HomeLayout } from "@/layout/Layout";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "@/components/page/home/Home";
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<HomeLayout />}>
            <Route path="/" element={<Home />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default App;
