import { HookContext, TContextHook } from "@/context/hook";
import React from "react";
import { WeatherRepo } from "@/datasource/repositories/weather.repo";
import { RootState } from "@/store";
import {
  IWeatherExpand,
  setSearchHistory,
  setWeatherData,
} from "@/store/weather";
import { setMsg } from "@/store/global";

const weatherRepo = new WeatherRepo();
export const useHome = () => {
  const hooks = React.useContext(HookContext) as TContextHook;
  const weather = hooks.useSelector((state: RootState) => state.weather);
  const { weatherData, searchHistory } = weather;
  const [formData, setFormData] = hooks.useState({ city: "", country: "" });
  const [searchMsg, setSearchMsg] = hooks.useState("");
  const [searchResult, setSearchResult] = hooks.useState<boolean | null>(null);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const searchAPI = (
    q: string,
    isInnit?: boolean,
    searchCity?: string,
    searchedCountry?: string
  ) => {
    weatherRepo.get(q).then((res) => {
      setSearchResult(null);
      if (res.success) {
        hooks.dispatch(
          setWeatherData({
            data: {
              ...res.data,
              searchedCity: searchCity ?? formData.city,
              searchedCountry: searchedCountry ?? formData.country,
              searchedTime: new Date().getTime(),
            },
            isInnit,
          })
        );
      }else{
				hooks.dispatch(setMsg({
					text: res.message,
					status: -1
				}))
			}
    });
  };
  // Search Action
  const searchWeather = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let { city, country } = formData;
    city = city?.trim();
    if (country) {
      country = country.replace(/\s/g, "");
    }
    if (!city && !country) {
      setSearchMsg("Please enter city and country");
      return;
    }
    const q = city && country ? `${city},${country}` : city || country;
    searchAPI(q);
  };
  const clearSearch = () => {
    setFormData({ city: "", country: "" });
    setSearchMsg("");
  };

  // Re Search Action
  const reSearchWeather = (item: IWeatherExpand) => {
    const { searchedCity, searchedCountry } = item;
    clearSearch();
    setFormData({
      city: searchedCity,
      country: searchedCountry,
    });
    const q =
      searchedCity && searchedCountry
        ? `${searchedCity},${searchedCountry}`
        : searchedCity || searchedCountry;
    searchAPI(q, false, searchedCity, searchedCountry);
  };

  // Remove Searched Action
  const removeHistory = (item: IWeatherExpand) => {
    const newHistoryList = searchHistory.filter(
      (history) => history.id !== item.id
    );
    hooks.dispatch(setSearchHistory(newHistoryList));
  };

  // Effect Side
  hooks.useEffect(() => {
    const q = "Ho Chi Minh";
    searchAPI(q, true);
  }, []);

  // Form Data Effect
  hooks.useEffect(() => {
    const onChangeSearch = () => {
      let { city, country } = formData;
      city = city?.trim();
      if (country) {
        country = country.replace(/\s/g, "");
      }
      if (city || country) {
        setSearchMsg("");
        const findHistory = searchHistory.find((item) => {
          const isCityMatch =
            !city || item.searchedCity.toLowerCase() === city.toLowerCase();
          const isCountryMatch =
            !country ||
            item.searchedCountry.toLowerCase() === country.toLowerCase();

          return isCityMatch && isCountryMatch;
        });
        if (findHistory) {
          setSearchResult(true);
          hooks.dispatch(
            setWeatherData({
              data: findHistory,
              isInnit: true,
            })
          );
        } else {
          setSearchResult(false);
        }
      } else {
        setSearchResult(null);
      }
    };
    setTimeout(() => {
      onChangeSearch();
    }, 100);
  }, [formData]);

  return {
    searchMsg,
    weatherData,
    formData,
    searchHistory,
    searchResult,
    clearSearch,
    onSearchChange,
    searchWeather,
    reSearchWeather,
    removeHistory,
  };
};
