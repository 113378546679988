import { iconWeather } from "@/constants/icons";
interface IProps {
	weatherIcon: string
}
const IconWeather: React.FC<IProps> = ({ weatherIcon }) => {
	switch (weatherIcon) {
		case iconWeather.Icon01d.code:
			return <img src={iconWeather.Icon01d.icon} alt={iconWeather.Icon01d.name} />
		case iconWeather.Icon01n.code:
			return <img src={iconWeather.Icon01n.icon} alt={iconWeather.Icon01n.name} />
		case iconWeather.Icon02d.code:
			return <img src={iconWeather.Icon02d.icon} alt={iconWeather.Icon02d.name} />
		case iconWeather.Icon02n.code:
			return <img src={iconWeather.Icon02n.icon} alt={iconWeather.Icon02n.name} />
		case iconWeather.Icon03d.code:
			return <img src={iconWeather.Icon03d.icon} alt={iconWeather.Icon03d.name} />
		case iconWeather.Icon03n.code:
			return <img src={iconWeather.Icon03n.icon} alt={iconWeather.Icon03n.name} />
		case iconWeather.Icon04d.code:
			return <img src={iconWeather.Icon04d.icon} alt={iconWeather.Icon04d.name} />
		case iconWeather.Icon04n.code:
			return <img src={iconWeather.Icon04n.icon} alt={iconWeather.Icon04n.name} />
		case iconWeather.Icon09d.code:
			return <img src={iconWeather.Icon09d.icon} alt={iconWeather.Icon09d.name} />
		case iconWeather.Icon09n.code:
			return <img src={iconWeather.Icon09n.icon} alt={iconWeather.Icon09n.name} />
		case iconWeather.Icon10d.code:
			return <img src={iconWeather.Icon10d.icon} alt={iconWeather.Icon10d.name} />
		case iconWeather.Icon10n.code:
			return <img src={iconWeather.Icon10n.icon} alt={iconWeather.Icon10n.name} />
		case iconWeather.Icon11d.code:
			return <img src={iconWeather.Icon11d.icon} alt={iconWeather.Icon11d.name} />
		case iconWeather.Icon11n.code:
			return <img src={iconWeather.Icon11n.icon} alt={iconWeather.Icon11n.name} />
		case iconWeather.Icon13d.code:
			return <img src={iconWeather.Icon13d.icon} alt={iconWeather.Icon13d.name} />
		case iconWeather.Icon13n.code:
			return <img src={iconWeather.Icon13n.icon} alt={iconWeather.Icon13n.name} />
		case iconWeather.Icon50d.code:
			return <img src={iconWeather.Icon50d.icon} alt={iconWeather.Icon50d.name} />
		case iconWeather.Icon50n.code:
			return <img src={iconWeather.Icon50n.icon} alt={iconWeather.Icon50n.name} />
		default:
			return <>N/A</>
	}
}
export default IconWeather;