import React from "react";
import clsx from "clsx";
import { classBox } from "@/assets/tailwind/classBox";
const Header: React.FC = () => {
  return (
    <>
      <div className="py-4 text-white fixed w-full border-b border-white !border-opacity-20 bg-white bg-opacity-10">
        <div className={clsx(classBox.userLayout, classBox.fbc)}>
          <h2>Today's Weather</h2>
          <div>
            <a href="https://www.linkedin.com/in/bui-phuc-87a474139/" target="_blank">Bui Phuc</a>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;